import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { UbicacionEvento } from './../models/ubicacion-evento';

@Injectable()
export class UbicacionEventoService {
	public http: HttpClient;
	public url: string;
	public endPoint: string;

	constructor(handler: HttpBackend) {
		this.http = new HttpClient(handler);
		this.endPoint = 'ubicacionEventoes/verificacion';
		this.url = environment.apiUrl + this.endPoint;
	}

	public registrar(ubicacion: UbicacionEvento): Observable<UbicacionEvento> {
		const headers = new HttpHeaders().set('content-type', 'application/json');

		const options = { headers: headers };
		return this.http
			.post<UbicacionEvento>(
				environment.apiUrl + 'ubicacionEventoes/verificacion',
				JSON.stringify(ubicacion),
				options
			)
			.pipe(take(1));
	}
}
