import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

export const AppRoutes: Routes = [
	{
		path: '',
		// component: AccesoStreamComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./acceso-stream/acceso-stream.module').then(m => m.AccesoStreamModule)
			},
		]
	},
	{
		path: '',
		component: AdminLayoutComponent,
		children: [
			{
				path: 'admin',
				loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
			},
			{
				path: 'administracion',
				loadChildren: () =>
					import('./administracion/administracion.module').then(m => m.AdministracionModule)
			},
			{
				path: 'configuracionEvento',
				loadChildren: () =>
					import('./administracion/configuracion-evento/configuracion-evento.module').then(m => m.ConfiguracionEventoModule)
			},
		]
	},
	{
		path: '',
		component: AuthLayoutComponent,
		children: [
			{
				path: 'autentificacion',
				loadChildren: () =>
					import('./autentificacion/autentificacion.module').then(
						(m) => m.AutentificacionModule
					)
			}
		]
	},
	{ path: '**', redirectTo: 'session/404' }
];
