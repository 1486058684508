import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {environment} from "../../environments/environment";

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
	constructor(private router: Router) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token: string = localStorage.getItem('tokenStream');
    const tokenPermissions: string = localStorage.getItem('tokenPermission');
		const token_type: string = localStorage.getItem('tokenType');

		// modifique ?token por &token
		if (token) {
      let valid = req.url.search(environment.apiUrlPermissions) == 0;
      let tokenHeader = valid ? tokenPermissions : token;
			req = req.clone({
				headers: req.headers.set('Authorization', token_type + ' ' + tokenHeader)
			});
		}

		if (!req.headers.has('Content-Type')) {

			req = req.clone({
				headers: req.headers.set('Content-Type', 'application/json')
			});
		}

		// cuando el content type es form data lo eliminamos para que el navegador pueda calcular el boundary
        if (req.headers.get('Content-Type') === 'multipart/form-data;') {
			req = req.clone({
				headers: req.headers.delete('Content-Type')
			})
		}


		if (!req.headers.has('Accept')) {
			req = req.clone({
				headers: req.headers.set('Accept', 'application/json')
			});
		}


		return next.handle(req).pipe(
			tap(
				() => {
					// success
				},
				(err: any) => {
					// error

					if (err instanceof HttpErrorResponse) {
						let error = err.error;

						if (typeof err.error === 'string') {
							error = JSON.parse(err.error);
						}
						if (err.status === 0) {
							// redirect user to login
							this.router.navigate(['/autentificacion/login']);
						}
					}
				}
			)
		);
	}
}
