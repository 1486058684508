import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { Usuario } from './../models/usuario';

@Injectable()
export class LoginService {
	constructor(public http: HttpClient) {}

	agregarHeadersLogin(): HttpHeaders {
		const headers = new HttpHeaders();
		return headers;
	}

	login(credencial: any): Observable<boolean> {
		this.borrarCredenciales();
		let usuario;
    let usuarioPermission;
		if (credencial.username.search('@') !== -1) {
			usuario = { email: credencial.username, password: credencial.password };
		} else {
			usuario = { dni: credencial.username, password: credencial.password };
      usuarioPermission = { username: credencial.username, password: credencial.password };
		}

		const headers = new HttpHeaders().set('content-type', 'application/json');

		const options = { headers: headers };

		return this.http
			.post(environment.apiUrl + 'authentication/signin', JSON.stringify(usuario), options)
			.pipe(
				map((data: any) => {
          this.loginPermissions(JSON.stringify(usuarioPermission))
            .subscribe((response: any) => {
              localStorage.setItem('tokenPermission', response.token);
            });
          this.guardarCredenciales(data);
          return true;
				})
			);
	}

  loginPermissions(user: any) {
    return this.http
      .post(environment.apiUrlPermissions + 'auth', user);
  }

	borrarCredenciales() {
		if (localStorage.length > 0) {
			localStorage.clear();
		}
	}

	guardarCredenciales(datosToken: any) {
		localStorage.setItem('tokenStream', datosToken.accessToken);
		localStorage.setItem('tokenType', datosToken.tokenType);
	}

	public crearUsuario(usuario: Usuario): Observable<Usuario> {
		return this.http.post<Usuario>(environment.apiUrl + 'Usuarios', JSON.stringify(usuario));
	}

	logout(): Observable<any> {
		return this.http.post(environment.apiUrl + 'Usuarios/logout', '').pipe(
			map((res) => {
				localStorage.clear();
			}),
			catchError(this.handleErrors)
		);
	}

	handleErrors(error: any) {
		localStorage.clear();
		return error;
	}
}
